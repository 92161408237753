export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')];

export const server_loads = [];

export const dictionary = {
	"/": [4],
	"/app/backoffice": [5,[2,3]],
	"/app/backoffice/blast-lists": [6,[2,3]],
	"/app/backoffice/clients": [7,[2,3]],
	"/app/backoffice/export": [8,[2,3]],
	"/app/backoffice/inputs": [9,[2,3]],
	"/app/backoffice/notifications": [10,[2,3]],
	"/app/backoffice/sync": [11,[2,3]],
	"/app/backoffice/users": [12,[2,3]],
	"/app/dashboard": [13,[2]],
	"/app/live": [14,[2]],
	"/app/whiteboard": [15,[2]],
	"/auth/login": [16],
	"/password-reset": [17],
	"/unsupported-browser": [18]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';